import { render, staticRenderFns } from "./content-header.vue?vue&type=template&id=691f21de&scoped=true"
import script from "./content-header.vue?vue&type=script&lang=js"
export * from "./content-header.vue?vue&type=script&lang=js"
import style0 from "./content-header.vue?vue&type=style&index=0&id=691f21de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691f21de",
  null
  
)

/* custom blocks */
import block0 from "../assets/I18N/Screen.Global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Ccomponents%5Ccontent-header.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports