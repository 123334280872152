import Vue from 'vue'

export default {
    registerAll: function () {


        Vue.filter('$', function (value) {
            if (value && value != 0) {
                value = value.toFixed(2);          
                return value;
            } else {
                return '0.00';
            }
            
        });

        Vue.filter('YYYYMMDD', function (value) {
            if (value && value != '0001-01-01T00:00:00+00:00' && value != '0001-01-01T00:00:00Z' ) {
                var d = new Date(value),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');

            } else {
                return '';
            }
        });

        Vue.filter('YYYYMMDDhhmm', function (value) {
            if (value && value != '0001-01-01T00:00:00+00:00' && value != '0001-01-01T00:00:00Z') {
                var d = new Date(value),
                    min = '' + d.getMinutes(),
                    hour = '' + d.getHours(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                if (min.length < 2)
                    min = '0' + min;

                var time = [hour, min].join(':');
                var other = [year, month, day].join('-');
                return other + ' ' + time;

            } else {
                return '';
            }
        });

        Vue.filter('phone', function (value) {
            if (value) {
                var phone = value.toString();

                var c = phone.substring(6, 10);
                var b = phone.substring(3, 6);
                var a = phone.substring(0, 3);

                return a + '-' + b + '-' + c;
            }
        });
        
        Vue.filter('textWithBR', function (value) {
            if (value) {
                //value = value.replace(/<script[^>]*>(?:(?!<\/script>)[^])*<\/script>/g, "?Script!?")
                value = value.replace(new RegExp('\r?\n', 'g'), '<br />');
                return value
            }
        });  
        Vue.filter('validations', function (value) {
            if (value) {
                if (value.$invalid) {
                    return 'md-invalid';
                }
            }
            return '';
        });      

    }
};
