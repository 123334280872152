export const apiECommerceExtension = {
    created: function () {
    },
methods: {
        isOrder: function(){
            let length = 0;
            var order = JSON.parse(localStorage.getItem("Order"));

            if(order){ try {length = order.items.length; } catch (error) { length = 0;} }
            return length;
        },
        GetOrder: async function(){    
            //localStorage.removeItem("Order");        
            let order = JSON.parse(localStorage.getItem("Order"));
            
            if(order){
                let consult = false;
                let modifiedDate = new Date(order.modifiedOn);
                let dateNow = new Date();
                const timeDifference = Math.abs(dateNow - modifiedDate) / 1000;
                if(timeDifference > 30) { consult = true; }

                if(consult == true) {
                    
                    try {
                        let token = await this.login();
                        delete order["validations"]; // need to delete because backend crash with this prop
                        let param = order;
                        let data = null;
                        let api = $App.url.api + 'eCommerce/Orders/Eval'
        
                        try {
        
                            let culture = "FR";
                            if(this.$i18n.locale) { culture = this.$i18n.locale; }
            
                            // ** ATTENTION need to add bearer token...
                            let response = await fetch(api, {
                                method: 'PUT',
                                mode: 'cors', // no-cors, *cors, same-origin
                                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                credentials: 'same-origin', // include, *same-origin, omit
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token,
                                    'Content-Language': culture.toUpperCase()
                                },
                                redirect: 'follow', // manual, *follow, error
                                referrerPolicy: 'no-referrer', // no-referrer, *client
                                body: JSON.stringify(param)
                            });
        
                            if (await response.ok) {
                                if (await response.status !== 204) {
                                    order = await response.json();
                                }
                            } else {
                                let msg = await response.text();
                            };
        
                        } catch (error) { alert('error'); /*$App.event.$emit("app_error", error);*/ }
                        
        
                    } catch (error) {
                        if ($App && $App.event) { }//$App.event.$emit("app_error", error); 
                        else { console.log(error); }
                        return null;
                    }
                }

            } else{ order = await this.GetDefaultOrder();}
            return order;
        },
        GetInvoice: async function(param){
            try {

                let invoice = null;
                let api = $App.url.api + "eCommerce/Invoices/" + param;

                try {
                    let token = await this.login();
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                          'Authorization': 'Bearer ' + token
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            invoice = await response.json();
                            invoice.paymentTypeId = invoice.paymentTypeId.toString();
                        }
                    } else { /*let msg = await response.text(); */};

                } catch (error) { console.log(error); }

                return invoice;

            } catch (error) { console.log(error); return null; }
        },
        GetInvoiceRef: function(){
            let invoiceRef = null;

            invoiceRef = localStorage.getItem("invoicRef");

            return invoiceRef;
        },
        GetDefaultOrder: async function(){
            try {

                let order = null;
                let api = $App.url.api + "Default/eCommerce/Order";

                try {
                    let token = await this.login();
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                          'Authorization': 'Bearer ' + token
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            order = await response.json();
                        }
                    } else { /*let msg = await response.text(); */};

                } catch (error) { console.log(error); }

                return order;

            } catch (error) { console.log(error); return null; }
            
        },
        AddItem: async function(product, options){
            let FinalOrder = null;
            let order = JSON.parse(localStorage.getItem("Order"));            

            let item = await this.GetDefaultItem();
            item.price = product.price;
            item.qty = 1;
            item.productId = product.id;
            item.number = product.accountingNumber;
            item.description = product.title; //.description; // title ** a chager FXGL
            item.options = options;
            
            if(order){
                order.items.push(item);   
            }
            else{ 
                order = await this.GetDefaultOrder();
                order.items.push(item);
            }

            FinalOrder = await this.UpdateOrder(order);

            if(FinalOrder){ return true } else { return false }
        },
        DeleteItem: async function(Itemindex){
            let order = await this.GetOrder();
            let FinalOrder = null;
            if(order){
                order.items.splice(Itemindex,1);
                FinalOrder = await this.UpdateOrder(order);
            }
            return FinalOrder;

        },
        ClearOrder: function(){
            let cleared = true;

            localStorage.removeItem('Order');

            var order = JSON.parse(localStorage.getItem("Order"));
            if(order){ this.cleared = false; }

            return cleared;
        },
        ClearInvoice: function(){
            let cleared = true;

            localStorage.removeItem('invoicRef');

            let ref = JSON.parse(localStorage.getItem("invoicRef"));
            if(ref){ this.cleared = false; }

            return cleared;
        },
        UpdateOrder: async function(order){
            let r = null;
            if(order){
                try {
                    let token = await this.login();
                    delete order["validations"]; // need to delete because backend crash with this prop
                    let param = order;
                    let api = $App.url.api + 'eCommerce/Orders/Eval'
    
                    try {

                        let culture = "FR";
                        if(this.$i18n.locale) { culture = this.$i18n.locale; }
    
                        // ** ATTENTION need to add bearer token...
                        let response = await fetch(api, {
                            method: 'PUT',
                            mode: 'cors', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'same-origin', // include, *same-origin, omit
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token,
                                'Content-Language': culture.toUpperCase()
                            },
                            redirect: 'follow', // manual, *follow, error
                            referrerPolicy: 'no-referrer', // no-referrer, *client
                            body: JSON.stringify(param)
                        });
    
                        if (await response.ok) {
                            if (await response.status !== 204) {                                
                                r = await response.json();
                                
                            }
                        } else {
                            let msg = await response.text();
                        };
    
                    } catch (error) { alert('error'); }
    
                } catch (error) {
                    if ($App && $App.event) { } else { console.log(error); } 
                }
            }
            
            if(r && r.items.length > 0) { localStorage.setItem('Order', JSON.stringify(r)); }
            else {localStorage.removeItem('Order');}

            return r;            
        },
        CompleteOrder: async function(order){

            let invoiceRef = null;
            let path = 'eCommerce/Orders/Complete'
            let api = $App.url.api + path;
            console.log(api);

            if(order){
                try {
                    let token = await this.login();
                    //delete order["validations"]; // need to delete because backend crash with this prop
                    let param = order;
                    let api = $App.url.api + 'eCommerce/Orders/Complete'
                    console.log('beforeTryCatch')
                    try {
                        console.log('meanwhileTryCatch')
                        // ** ATTENTION need to add bearer token...
                        let response = await fetch(api, {
                            method: 'POST',
                            mode: 'cors', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'same-origin', // include, *same-origin, omit
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                            redirect: 'follow', // manual, *follow, error
                            referrerPolicy: 'no-referrer', // no-referrer, *client
                            body: JSON.stringify(param)
                        });
                        console.log('meanwhileTryCatch')
                        if (await response.ok) {
                            if (await response.status !== 204) {   
                                let data = await response.json();
                                invoiceRef = data.uId;
                            }
                        } else {
                            let msg = await response.text();
                        };
    
                    } catch (error) { alert('error'); }
                    console.log('afterTryCatch')
    
                } catch (error) {
                    if ($App && $App.event) { } else { alert('error'); } 
                }
            }
            if(invoiceRef){ localStorage.setItem('invoicRef', JSON.stringify(invoiceRef)); }
            return invoiceRef;

        },
        CreditCardPaiement: async function(sid,creditCardInfo){
            let err = null;
            if(sid){
                try {
                    let token = await this.login();
                    let param = creditCardInfo;
                    let api = $App.url.api + 'eCommerce/Invoices/' + sid + '/TIB/Pay';

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: JSON.stringify(param)
                    });

                    if (await response.ok) {
                        err = null;
                        // if (await response.status !== 204) { 
                        //     urlToComplete = true;
                        // }
                    } else {
                        err = await response.text();
                        //$App.event.$emit("app_error", msg);
                    };
                } catch (e) {
                    console.log(e);
                    err = e.message;
                    //$App.event.$emit("app_error", e); 
                }
            } else { err = "sid is mandatory"; }

            return err;
        },
        PaiementOrder: async function(sid){
            let Completed = false;
            if(sid){
                try {
                    let token = await this.login();
                    let api = $App.url.api + 'eCommerce/Invoices/' + sid + '/PayPal/Pay';
    
                    try {
    
                        // ** ATTENTION need to add bearer token...
                        let response = await fetch(api, {
                            method: 'GET',
                            mode: 'cors', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'same-origin', // include, *same-origin, omit
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                            redirect: 'follow', // manual, *follow, error
                            referrerPolicy: 'no-referrer', // no-referrer, *client
                            //body: JSON.stringify(param)
                        });
    
                        if (await response.ok) {
                            if (await response.status !== 204) {     
                                Completed = true;
                                Order = await response.text();
                            }
                        } else {
                            let msg = await response.text();
                        };
    
                    } catch (error) { alert('error'); }
    
                } catch (error) {
                    if ($App && $App.event) { } else { console.log(error); } 
                }
            }
            return Completed;
        },
        PaypalInit: async function(sid){
            let urlToComplete = "";
            console.log('Paypal Init');
            if(sid){
                try {
                    let token = await this.login();
                    let api = $App.url.api + 'eCommerce/Invoices/' + sid + '/PayPal/Pay';
                    console.log(api);
                    
                    try {
    
                        // ** ATTENTION need to add bearer token...
                        let response = await fetch(api, {
                            method: 'POST',
                            mode: 'cors', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'same-origin', // include, *same-origin, omit
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                            redirect: 'follow', // manual, *follow, error
                            referrerPolicy: 'no-referrer', // no-referrer, *client
                            //body: JSON.stringify(param)
                        });
    
                        if (await response.ok) {
                            if (await response.status !== 204) {     
                                urlToComplete = await response.text();
                                console.log(urlToComplete);
                            }
                        } else {
                            let msg = await response.text();
                        };
    
                    } catch (error) { alert('error'); }
    
                } catch (error) {
                    if ($App && $App.event) { } else { console.log(error); } 
                }
            }
            return urlToComplete;
        },
        PaypalPaided: async function(sid, exec){
            let Completed = false;
            if(sid){
                try {
                    let token = await this.login();
                    let api = $App.url.api + 'eCommerce/Invoices/' + sid + '/PayPal/Pay';
                    console.log(api);
                    try {
    
                        // ** ATTENTION need to add bearer token...
                        let response = await fetch(api, {
                            method: 'PUT',
                            mode: 'cors', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'same-origin', // include, *same-origin, omit
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                            redirect: 'follow', // manual, *follow, error
                            referrerPolicy: 'no-referrer', // no-referrer, *client
                            body: JSON.stringify(exec)
                        });
    
                        if (await response.ok) {
                            if (await response.status !== 204) {     
                                Completed = true;
                            }
                        } else {};
    
                    } catch (error) { alert('error'); }
    
                } catch (error) {
                    if ($App && $App.event) { } else { console.log(error); } 
                }
            }
            return Completed;
        },
        PaypalCanceled: async function(sid){
            let Completed = false;
            if(sid){
                try {
                    let token = await this.login();
                    let api = $App.url.api + 'eCommerce/Invoices/' + sid + '/PayPal/Cancel';
                    //console.log(api);
                    try {
    
                        // ** ATTENTION need to add bearer token...
                        let response = await fetch(api, {
                            method: 'GET',
                            mode: 'cors', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'same-origin', // include, *same-origin, omit
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                            redirect: 'follow', // manual, *follow, error
                            referrerPolicy: 'no-referrer', // no-referrer, *client
                            //body: JSON.stringify(param)
                        });
    
                        if (await response.ok) {
                            if (await response.status !== 204) {Completed = true;}
                        } else {};
    
                    } catch (error) { alert('error'); }
    
                } catch (error) {console.log(error);}
            }
            return Completed;
        },
        login: async function () {
            let api = $App.url.api + 'security/login/Helite';
            let data = null;
            try {
                let response = await fetch(api, {
                    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
                })
                if (await response.ok) {
                    if (await response.status !== 204) {
                        let data = await response.text();

                        if (data !== null && data !== '' && data !== undefined) {
                            sessionStorage.setItem('user-token', data);

                        } else {
                            sessionStorage.removeItem('user-token');
                            sessionStorage.removeItem('autorized');
                        }
                        return data
                    }
                } else {
                    let msg = await response.text();
                    $App.event.$emit("app_error", msg);
                };
                    
            } catch (error) {
                console.log(error);
            }
        },
        GetDefaultItem: async function(){
            try {

                let item = null;
                let api = $App.url.api + "Default/eCommerce/OrderItem";
        
                try {
                    let token = await this.login();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                          'Authorization': 'Bearer ' + token
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            item = await response.json();
                        }
                    } else {
                        //let msg = await response.text();
                    };

                } catch (error) {
                    console.log(error);
                }

                return item;

            } catch (error) {
                console.log(error);
                return null;
            }
        },
        GetDefaultAddress: async function(){
            try {

                let item = null;
                let api = $App.url.api + "Default/Address";
                try {
                    let token = await this.login();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                          'Authorization': 'Bearer ' + token
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            item = await response.json();
                            
                        }
                    } else {
                        //let msg = await response.text();
                    };

                } catch (error) {
                    console.log(error);
                }
                
                return item;

            } catch (error) {
                console.log(error);
                return null;
            }
        },
        _EcommerceProductGetById: async function(){
            try {

                let data = null;
                let id = this.$route.params.id;
                let api = $App.url.api + "eCommerce/Products/"+ this.$route.params.id;
                let culture = "FR";
                if(this.$i18n.locale) { culture = this.$i18n.locale; }

                //https://abstracxion.wx3data.com/ || http://localhost:17548/ || Tenant/FBergeron/Sale/Products/filter
                try {
                    let token = await this.login();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                          'Authorization': 'Bearer ' + token,
                          'Content-Language': culture.toUpperCase()
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };

                } catch (error) {
                    $App.event.$emit("app_error", error);
                }

                return data;

            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
        EcommerceProductGetById: async function(id){
            try {

                let data = null;
                let api = $App.url.api + "eCommerce/Products/"+ id;
                let culture = "FR";
                if(this.$i18n.locale) { culture = this.$i18n.locale; }
                //console.log(culture);

                //https://abstracxion.wx3data.com/ || http://localhost:17548/ || Tenant/FBergeron/Sale/Products/filter
                try {
                    let token = await this.login();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                          'Authorization': 'Bearer ' + token,
                          'Content-Language': culture.toUpperCase()
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };

                } catch (error) {
                    $App.event.$emit("app_error", error);
                }

                return data;

            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
        EcommerceProductsList: async function(range,callbackfct){
            try {
                let data = null;
                let api = $App.url.api + 'eCommerce/Products/filter';
                let filter = {"categoryId": null};
                let culture = "FR";
                if(this.$i18n.locale) { culture = this.$i18n.locale; }

                try {
                    let token = await this.login();
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'PUT',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                            'Range': range,
                            'Content-Language': culture.toUpperCase()
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: JSON.stringify(filter)
                    });
    
                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                            if(callbackfct) { callbackfct(response); }
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };
    
                } catch (error) {
                    $App.event.$emit("app_error", error);
                }
    
                return data;
    
            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
        EcommerceProductsListWithFilter: async function(filter,range,callbackfct){
            try {
                let data = null;
                let api = $App.url.api + 'eCommerce/Products/filter';
                let Filter = filter;
                let culture = "FR";
                if(this.$i18n.locale) { culture = this.$i18n.locale; }
                try {
                    let token = await this.login();
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'PUT',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                            'Range': range,
                            'Content-Language': culture.toUpperCase()
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: JSON.stringify(Filter)
                    });
    
                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                            if(callbackfct) { callbackfct(response); }
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };
    
                } catch (error) {
                    $App.event.$emit("app_error", error);
                }
    
                return data;
    
            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
        EcommerceCategoriesList: async function(){
            try {

                let data = null;
                let api = $App.url.api + 'eCommerce/ProductCategories';
                let culture = "FR";
                if(this.$i18n.locale) { culture = this.$i18n.locale; }

                try {
                    let token = await this.login();
                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                            'Content-Language': culture.toUpperCase()
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                    });
    
                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };
    
                } catch (error) {
                    $App.event.$emit("app_error", error);
                }
    
                // Hide progress bar
                //$App.event.$emit("app_running", false);
    
                return data;
    
            } catch (error) {
                $App.event.$emit("app_error", error);
                return null;
            }
        },
    }
}
