import { render, staticRenderFns } from "./infoPayment.vue?vue&type=template&id=099c63d0&scoped=true"
import script from "./infoPayment.vue?vue&type=script&lang=js"
export * from "./infoPayment.vue?vue&type=script&lang=js"
import style0 from "./infoPayment.vue?vue&type=style&index=0&id=099c63d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "099c63d0",
  null
  
)

/* custom blocks */
import block0 from "../assets/I18N/eCommerce/infoPayment.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Ccomponents%5CinfoPayment.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./infoPayment.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports