import { render, staticRenderFns } from "./Gp_air.vue?vue&type=template&id=60e99ca0&scoped=true"
import script from "./Gp_air.vue?vue&type=script&lang=js"
export * from "./Gp_air.vue?vue&type=script&lang=js"
import style0 from "./Gp_air.vue?vue&type=style&index=0&id=60e99ca0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e99ca0",
  null
  
)

/* custom blocks */
import block0 from "../../assets/I18N/Screen.Global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5Cproducts%5CGp_air.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "../../assets/I18N/products/Gp_air.json?vue&type=custom&index=1&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5Cproducts%5CGp_air.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports