import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=3b57164f&scoped=true"
import script from "./Product.vue?vue&type=script&lang=js"
export * from "./Product.vue?vue&type=script&lang=js"
import style0 from "./Product.vue?vue&type=style&index=0&id=3b57164f&prod&lang=css"
import style1 from "./Product.vue?vue&type=style&index=1&id=3b57164f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b57164f",
  null
  
)

/* custom blocks */
import block0 from "../assets/I18N/Screen.Global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CHelite%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5CProduct.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./Product.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports